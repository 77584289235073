<template>
  <div class="_bg-default _80w _100vh">
    <div class="_100top pa-3">
      <v-card class="radius-card mb-5" :loading="loading" min-height="150px">
        <!-- {{ content }} -->
        <div v-if="content" class="pa-5">
          <v-row>
            <v-col cols="2">About Us</v-col>
            <v-col cols="10">
              <vue-editor
                placeholder="About Us"
                :editorToolbar="customToolbar"
                v-model="content.about_us"
              ></vue-editor>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">Visi</v-col>
            <v-col cols="10">
              <v-textarea solo v-model="content.visi" label="Visi"></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="2">Misi</v-col>
            <v-col cols="10">
              <v-row v-for="(item, idx) in tempMisi" :key="idx">
                <v-col cols="11"
                  ><v-text-field
                    v-model="tempMisi[idx]"
                    hide-details="auto"
                    required
                    solo
                    :id="`opt-${idx + 1}`"
                  ></v-text-field
                ></v-col>
                <v-col cols="1">
                  <v-btn
                    fab
                    depressed
                    color="red"
                    dark
                    small
                    @click="removeMisi(item)"
                    ><v-icon>mdi-delete</v-icon></v-btn
                  >
                </v-col>
              </v-row>
              <v-btn @click="addMisi" small color="purple" dark class="mt-5"
                ><v-icon>mdi-plus</v-icon> Add item</v-btn
              >
            </v-col>
          </v-row>
          <div class="d-flex justify-end">
            <v-btn color="purple" dark @click="updateItem" v-if="!loadSave"
              ><v-icon class="mr-2">mdi-check</v-icon> Save</v-btn
            >
            <v-btn color="purple" dark v-if="loadSave">Loading...</v-btn>
          </div>
        </div>
      </v-card>
      <v-card class="pa-5 radius-card mb-2">
        <div class="d-flex justify-space-between">
          <h3>Milestone</h3>
          <v-btn color="purple" dark @click="dialogAddMilestone = true"
            ><v-icon class="mr-2">mdi-plus</v-icon> Add Milestone</v-btn
          >
        </div>
        <div v-if="milestone">
          <v-data-table
            :headers="headers"
            :items="milestone"
            class="elevation-0 my-5"
          >
            <template v-slot:[`item.actions`]="{ item }">
              <div class="d-flex">
                <v-btn
                  icon
                  depressed
                  outlined
                  color="blue"
                  class="mr-2"
                  content="Update Item"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 100
                  }"
                  @click="preUpdateMilestone(item)"
                >
                  <v-icon dark small> mdi-pencil </v-icon>
                </v-btn>
                <v-btn
                  icon
                  depressed
                  outlined
                  color="red"
                  class="mr-2"
                  content="Delete Item"
                  v-tippy="{
                    arrow: true,
                    arrowType: 'round',
                    animation: 'fade',
                    theme: 'light',
                    maxWidth: 100
                  }"
                  @click="deleteMilestone(item)"
                >
                  <v-icon dark small> mdi-delete </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </div>
      </v-card>
      <div class="pa-3">
        <CreateMiestone
          v-bind:dialogAddMilestone="dialogAddMilestone"
          @close="closeDialog"
        />
        <UpdateMiestone
          v-bind:dialogUpdateMilestone="dialogUpdateMilestone"
          v-bind:dataItem="dataItem"
          @close="closeDialog"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { VueEditor } from "vue2-editor";
import Swal from "sweetalert2";
import CreateMiestone from "./Modal/createMiestone.vue";
import UpdateMiestone from "./Modal/updateMiestone.vue";

export default {
  name: "aboutUs",
  components: {
    VueEditor,
    CreateMiestone,
    UpdateMiestone
  },
  computed: {
    ...mapState({
      content: state => state.aboutus.about,
      milestone: state => state.aboutus.milestone
    })
  },

  data() {
    return {
      loading: false,
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" }
        ],
        ["link"],
        [{ color: [] }]
      ],
      tempMisi: [],
      elmnt: 0,
      loadSave: false,
      dialogAddMilestone: false,
      dialogUpdateMilestone: false,
      dataItem: null,
      headers: [
        { text: "ID", value: "id" },
        { text: "TITLE", value: "judul" },
        { text: "DESCRIPTION", value: "keterangan" },
        {
          text: "DATE",
          value: `waktu`
        },
        { text: "ACTIONS", value: "actions" }
      ]
    };
  },

  mounted() {
    this.fetch();
    this.fetchMilestone();
  },

  methods: {
    fetch() {
      this.loading = true;
      this.$store.dispatch("aboutus/getAbout").then(data => {
        if (data) {
          this.tempMisi = data.misi;
          this.elmnt = data.misi.length;
          this.loading = false;
        }
      });
    },
    fetchMilestone() {
      this.loading = true;
      this.$store.dispatch("aboutus/getMilestone").then(data => {
        if (data) {
          this.loading = false;
        }
      });
    },
    updateItem() {
      this.loadSave = true;
      this.tempMisi = this.tempMisi.filter(item => {
        return item !== "";
      });
      let data = {
        about_us: this.content.about_us,
        visi: this.content.visi,
        misi: this.tempMisi
      };
      this.$store
        .dispatch("aboutus/updateAbout", { data: data })
        .then(data => {
          console.log(data.data);
          Swal.fire({
            icon: "success",
            title: data.data.message,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
          this.loadSave = false;
        })
        .catch(err => {
          Swal.fire({
            icon: "success",
            title: err,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
          this.loadSave = false;
          console.log(err);
        });
    },
    addMisi() {
      this.tempMisi.push("");
      this.elmnt = this.elmnt + 1;
      setTimeout(() => this.elementFocused(this.elmnt), 100);
    },
    removeMisi(item) {
      const idx = this.tempMisi.indexOf(item);
      if (idx > -1) {
        this.tempMisi.splice(idx, 1);
      }
    },
    elementFocused(count) {
      document.getElementById(`opt-${count}`).focus();
    },
    closeDialog() {
      this.dialogAddMilestone = false;
      this.dialogUpdateMilestone = false;
      this.fetchMilestone();
    },
    preUpdateMilestone(item) {
      this.dataItem = item;
      this.dialogUpdateMilestone = true;
    },
    deleteMilestone(item) {
      Swal.fire({
        title: "Are You Sure?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3F51B5",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes",
        cancelButtonText: "Cancel",
        position: "top",
        toast: true
      }).then(result => {
        if (result.isConfirmed) {
          //if ok then hit api delete iten
          this.$store
            .dispatch("aboutus/deleteMilestone", { id: item.id })
            .then(() => {
              this.fetchMilestone();
              Swal.fire({
                title: "Deleted",
                icon: "success",
                showConfirmButton: false,
                timer: 1000,
                position: "top",
                toast: true
              });
            })
            .catch(err => {
              Swal.fire({
                title: err.message,
                icon: "error",
                showConfirmButton: false,
                timer: 1000,
                position: "top",
                toast: true
              });
            });
        }
      });
    }
  }
};
</script>

<style scoped>
._60-w {
  width: 90%;
}
</style>
