<template>
  <v-row justify="start">
    <v-dialog v-model="dialogAddMilestone" persistent max-width="400px">
      <v-card class="radius-card">
        <v-toolbar dense flat>
          <span class="headline">Add Milestone</span>
          <v-spacer></v-spacer>
          <v-btn icon color="red" @click="$emit('close')"
            ><v-icon>mdi-close</v-icon></v-btn
          >
        </v-toolbar>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  label="Judul"
                  required
                  outlined
                  dense
                  hide-details="auto"
                  v-model="judul"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-textarea
                  label="Keterangan"
                  required
                  outlined
                  hide-details="auto"
                  dense
                  v-model="keterangan"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-menu
                  v-model="calendar"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="waktu"
                      label="Waktu"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      hide-details
                      dense
                      outlined
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="waktu"
                    @input="calendar = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            outlined
            rounded
            @click="createItem"
            v-if="!loading"
            >Save</v-btn
          >
          <v-btn color="blue" outlined rounded v-if="loading">Loading...</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "addmilestone",

  props: ["dialogAddMilestone"],

  data() {
    return {
      judul: "",
      keterangan: "",
      waktu: null,
      calendar: false,
      loading: false
    };
  },
  methods: {
    createItem() {
      this.loading = true;
      let data = {
        judul: this.judul,
        keterangan: this.keterangan,
        waktu: this.waktu
      };
      this.$store
        .dispatch("aboutus/createMilestone", data)
        .then(data => {
          this.loading = false;
          if (data.data.status == "success") {
            this.nameCampus = "";
            this.$emit("close");
            Swal.fire({
              icon: "success",
              title: data.data.message,
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000
            });
          } else {
            Swal.fire({
              icon: "error",
              title: data.data.data.errors.nama[0],
              showConfirmButton: false,
              position: "top",
              toast: true,
              timer: 3000
            });
          }
        })
        .catch(err => {
          this.loading = false;
          Swal.fire({
            icon: "error",
            title: err,
            showConfirmButton: false,
            position: "top",
            toast: true,
            timer: 3000
          });
        });
    }
  }
};
</script>

<style></style>
